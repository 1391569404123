<template>
  <div v-if="isOwner" id="paymentSettingContainer">
    <CRow class="mt-3" id="paymentSettingHeaderRow">
      <CCol md="12" class="text-center" id="paymentSettingHeaderCol">
      <div class="d-inline-flex align-items-start" id="paymentSettingHeaderTitle">
        <h2 class="font-weight-medium text-dark mb-0" id="paymentSettingTitle">
          {{ $t('payment') }}
        </h2>
        <HelpButton :isHeader="false" class="ml-2 mt-2" id="paymentSettingHelpButton"></HelpButton>
      </div>
       

        <h6 class="text-description" id="paymentSettingDescription">
          {{ $t('paymentDescriptionConfig') }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    <ul class="list-group" id="paymentSettingListGroup">
      <li
        class="list-group-item"
        v-for="(list, index) in paymentLists"
        :key="index"
        :id="`paymentSettingListItem-${index}`"
      >
        <div class="row" :id="`paymentSettingListRow-${index}`">
          <div class="col" :id="`paymentSettingListCol-${index}`">
            <img :src="list.icon" alt="" width="10%" :id="`paymentSettingListIcon-${index}`"/>
            {{ $t(list.label) }}
          </div>
          <div class="col text-right" :id="`paymentSettingSwitchCol-${index}`">
            <CSwitch
              color="success"
              :checked.sync="list.value"
              @update:checked="updateShopConfig(list.field, list.value)"
              :id="`paymentSettingSwitch-${index}`"
            />
            <div v-if="list.id === 'promptpay'" id="paymentSettingPromptpayContainer">
              <div v-if="promptpayEdited === false" id="paymentSettingPromptpayView">
                <input
                  v-model="shopsetting.promptPayId"
                  type="text"
                  placeholder="Mobile No. / ID Card /TAX ID"
                  class="form-control text-right"
                  disabledplaceholder
                  id="paymentSettingPromptpayIdInput"
                />
                <input
                  v-model="shopsetting.promptPayAccName"
                  type="text"
                  placeholder="Account name"
                  class="form-control text-right mt-1"
                  disabled
                  id="paymentSettingPromptpayNameInput"
                />
                <button
                  class="mt-1 btn btn-info btn-sm btn-block"
                  @click="promptpayEdited = true"
                  id="paymentSettingPromptpayEditButton"
                >
                {{ $t('edit') }}
                </button>
              </div>
              <div v-else-if="promptpayEdited === true" id="paymentSettingPromptpayEdit">
                <input
                  v-model="shopsetting.promptPayId"
                  type="text"
                  placeholder="Mobile No. / ID Card /TAX ID"
                  class="form-control text-right"
                  id="paymentSettingPromptpayIdEditInput"
                />
                <input
                  v-model="shopsetting.promptPayAccName"
                  type="text"
                  placeholder="Account name"
                  class="form-control text-right mt-1"
                  id="paymentSettingPromptpayNameEditInput"
                />
                <button
                  class="mt-1 btn btn-success btn-sm btn-block"
                  @click="
                    updatePromptpayConfig(
                      shopsetting.promptPayId,
                      shopsetting.promptPayAccName
                    )
                  "
                  id="paymentSettingPromptpaySaveButton"
                >
                {{ $t('save') }}
                </button>
              </div>
            </div>
            <div v-if="list.id === 'linepay'" id="paymentSettingLinepayContainer">
              <div v-if="linepayEdited === false" id="paymentSettingLinepayView">
                <div>
                  <input
                    v-if="lineChannelId != ''"
                    type="text"
                    class="form-control text-right"
                    :placeholder="lineChannelIdHidden"
                    disabled
                    id="paymentSettingLinepayChannelId"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control text-right"
                    placeholder="Channel ID"
                    disabled
                    id="paymentSettingLinepayChannelIdPlaceholder"
                  />
                </div>
                <div>
                  <input
                    v-if="lineChannelSecret != ''"
                    type="text"
                    class="form-control text-right mt-1"
                    :placeholder="lineChannelSecretHidden"
                    disabled
                    id="paymentSettingLinepayChannelSecret"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control text-right mt-1"
                    placeholder="Channel Secret"
                    disabled
                    id="paymentSettingLinepayChannelSecretPlaceholder"
                  />
                </div>

                <button
                  class="mt-1 btn btn-info btn-sm btn-block"
                  @click="linepayEdited = true"
                  id="paymentSettingLinepayEditButton"
                >
                {{ $t('edit') }}
                </button>
              </div>
              <div v-else-if="linepayEdited === true" id="paymentSettingLinepayEdit">
                <input
                  v-model="lineChannelId"
                  type="text"
                  placeholder="Channel ID"
                  class="form-control text-right"
                  id="paymentSettingLinepayChannelIdEditInput"
                />
                <input
                  v-model="lineChannelSecret"
                  type="text"
                  placeholder="Channel Secret"
                  class="form-control text-right mt-1"
                  id="paymentSettingLinepayChannelSecretEditInput"
                />
                <button
                  class="mt-1 btn btn-success btn-sm btn-block"
                  @click="
                    updatePaymentConfig({
                      line: {
                        channelId: lineChannelId,
                        channelSecret: lineChannelSecret,
                      },
                    })
                  "
                  id="paymentSettingLinepaySaveButton"
                >
                {{ $t('save') }}
                </button>
              </div>
            </div>
            <div v-if="list.id === 'thaidotcom'" id="paymentSettingThaidotcomContainer">
              <div v-if="thaidotcomEdited === false" id="paymentSettingThaidotcomView">
                <input
                  v-if="merchantId !== ''"
                  type="text"
                  class="form-control text-right"
                  :placeholder="merchantIdHidden"
                  disabled
                  id="paymentSettingThaidotcomMerchantId"
                />
                <input
                  v-else
                  type="text"
                  class="form-control text-right"
                  placeholder="Merchant ID"
                  disabled
                  id="paymentSettingThaidotcomMerchantIdPlaceholder"
                />
                <button
                  class="mt-1 btn btn-info btn-sm btn-block"
                  @click="thaidotcomEdited = true"
                  id="paymentSettingThaidotcomEditButton"
                >
                {{ $t('edit') }}
                </button>
              </div>
              <div v-else-if="thaidotcomEdited === true" id="paymentSettingThaidotcomEdit">
                <input
                  v-model="merchantId"
                  type="text"
                  placeholder="Merchant ID"
                  class="form-control text-right"
                  id="paymentSettingThaidotcomMerchantIdEditInput"
                />
                <button
                  class="mt-1 btn btn-success btn-sm btn-block"
                  @click="
                    updatePaymentConfig({
                      thaidotcom: { merchantId: merchantId },
                    })
                  "
                  id="paymentSettingThaidotcomSaveButton"
                >
                {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import pos from '@/services/local'
import util from '@/util/util'
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components:{
    HelpButton
  },
  data() {
    return {
      lineChannelId: '',
      lineChannelSecret: '',
      lineChannelIdHidden: 'xxx',
      lineChannelSecretHidden: 'xxx',
      promptpayEdited: false,
      linepayEdited: false,
      thaidotcomEdited: false,
      merchantId: '',
      thaidotcomCustomItem: '',
      merchantIdHidden: '',
    }
  },
  created() {
    this.getPaymentConfig()
    this.findThaidotcomCustomPayment()
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'shopsetting', 'isOwner']),
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    paymentLists() {
      if (this.shopsetting) {
        let paylists = [
          {
            id: 'creditcard',
            label: 'creditCard',
            value: this.shopsetting.paymentCredit,
            field: 'paymentCredit',
            icon:
              '/img/visa-mastercard-icon-8.jpg',
          },
          {
            id: 'promptpay',
            label: 'promptPay',
            value: this.shopsetting.paymentPromptpay,
            field: 'paymentPromptpay',
            icon: 'https://payment.webpakpay.com/assets/img/provider/tq.png',
          },
          {
            id: 'coupon',
            label: 'coupon',
            value: this.shopsetting.paymentCoupon,
            field: 'paymentCoupon',
            icon: 'https://cdn-icons-png.flaticon.com/512/726/726448.png',
          },
          
          // ****** Deprecated
          // {
          //   id: 'quickpay',
          //   label: 'quickpay',
          //   value: this.shopsetting.paymentQP,
          //   field: 'paymentQP',
          //   icon:
          //     'https://play-lh.googleusercontent.com/AIq5STppSfBfXtYckoE6oUrtET97717Ytt_6qjg0C-bSy7cN7bsYK03V6nDrNN8OIOI=s180-rw',
          // },
          {
            id: 'deposit',
            label: 'deposit',
            value: this.shopsetting.paymentDeposit,
            field: 'paymentDeposit',
            icon: 'https://cdn-icons-png.flaticon.com/512/2721/2721122.png',
          },
          // {
          //   id: 'alipay',
          //   label: 'alipay',
          //   value: this.shopsetting.paymentAlipay,
          //   field: 'paymentAlipay',
          //   icon:
          //     'https://img2.thaipng.com/20180629/lyu/kisspng-alipay-logo-alibaba-group-wechat-wechat-pay-5b3664b1b31cf6.5355558115302913777337.jpg',
          // },
          {
            id: 'linepay',
            label: 'linepay',
            value: this.shopsetting.enabledLinePay,
            field: 'enabledLinePay',
            icon: '/img/icon/linepay_logo.jpg',
          },
          {
            id: 'thaidotcom',
            label: 'thaidotcom',
            value: this.shopsetting.enabledThaidotcom,
            field: 'enabledThaidotcom',
            icon: '/img/thaidotcom.png',
          },
        ]

        return paylists
      }
    },
  },
  methods: {
    getPaymentConfig() {
      pos({
        url: '/api/v1.0/' + this.uid + '/shop/paymentconfig/data',
        params: { shopObjectId: this.shopObjectId },
        method: 'get',
      }).then((res) => {
        if (res.data.data !== null) {
          let linedata = res.data.data.line
          let thaidotcomdata = res.data.data.thaidotcom

          if (linedata != undefined) {
            this.lineChannelId = linedata.channelId
            this.lineChannelSecret = linedata.channelSecret
            this.lineChannelIdHidden = util.hideID(this.lineChannelId)
            this.lineChannelSecretHidden = util.hideID(this.lineChannelSecret)
          }

          if (thaidotcomdata != undefined) {
            this.merchantId = thaidotcomdata.merchantId
            this.merchantIdHidden = util.hideID(this.merchantId)
          }
        }
      })
    },
    updatePaymentConfig(body) {
      let data = {
        shopObjectId: this.shopObjectId,
        ...body,
      }

      pos({
        url: '/api/v1.0/' + this.uid + '/Shop/paymentconfig/create',
        data: data,
        method: 'post',
      }).then((res) => {
        this.linepayEdited = false
        this.thaidotcomEdited = false
      })
    },
    updateShopConfig(paymentType, paymentValue) {
      let data = {
        objectId: this.shopObjectId,
        [paymentType]: paymentValue,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + this.uid + '/Shop/updateconfig',
        data: data,
      })
        .then((res) => {
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updatePromptpayConfig(promptPayId, promptPayAccName) {
      let data = {
        objectId: this.shopObjectId,
        promptPayId: promptPayId,
        promptPayAccName: promptPayAccName,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + this.uid + '/Shop/updateconfig',
        data: data,
      })
        .then((res) => {
          this.promptpayEdited = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    findThaidotcomCustomPayment() {
      let id = 'thaidotcom'

      pos({
        method: 'get',
        url: '/api/v1.0/' + this.uid + '/custompayment/' + id + '/getbyId',
      })
        .then((res) => {
          if (res.data.error.code === 0) {
            this.thaidotcomCustomItem = res.data.data

            if (this.thaidotcomCustomItem === null) {
              this.createThaidotcomCustomPayment()
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    createThaidotcomCustomPayment() {
      let data = [
        {
          objectId: util.createObjectId(),
          id: 'thaidotcom',
          uid: this.uid,
          name: 'Thai Dot Com Payment',
          created_at: new Date(),
          updated_at: new Date(),
          index: 1,
        },
      ]

      pos({
        method: 'post',
        url: '/api/v1.0/' + this.uid + '/CustomPaymentItem/create',
        data: data,
      })
        .then((res) => {
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
